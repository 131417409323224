<template>
<div>
  <img
      v-if="appSkin === 'light'"
     :src="require('@/assets/images/logo/logo.png')"
      class="mr-1"
      height="80"
      width="200"
      alt="Kass Yazılım"
  >
  <img       
      v-if="appSkin === 'dark'"
     :src="require('@/assets/images/logo/logo-white.png')"
      class="mr-1"
      height="80"
      width="200"
      alt="Kass Yazılım">
</div>
</template>
<script>
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  data() {
    return { 
      appSkin: {},
    }
  },
  created() {
    const { skin } = useAppConfig();
    this.appSkin = skin.value;
  },
}
</script>
